<template>
    <div>
        <div>
            <Breadcrumb content="添加卡组"/>
        </div>
        <div class="m-bg m-pd">
            <el-row>
                <el-col :span="16">
                    <el-form v-model="form" label-width="100px" >
                        <el-form-item label="标题">
                            <el-input v-model="form.title"></el-input>
                        </el-form-item>
                        <el-form-item label="名称">
                            <el-input v-model="form.nav_title"></el-input>
                        </el-form-item>
                        <el-form-item label="编辑时间">
                            <el-date-picker type="date" placeholder="选择日期" v-model="form.edit_at"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="卡组ID">
                            <el-select  v-model="form.deck_id">
                                <el-option
                                        v-for="item in deckOptions"
                                        :key="item.id"
                                        :label="item.id"
                                        :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="顶部内容">
                            <Editor @getEditorVal="getEditVal"></Editor>
                        </el-form-item>
                        <!--官方卡组有-->
                        <div v-if="$route.query.type == 2">
                            <el-form-item label="是否显示">
                                <el-select v-model="form.card_detail_show">
                                    <el-option label="显示" value="1"></el-option>
                                    <el-option label="隐藏" value="0"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="关键卡片">
                                <el-button type="warning" @click="addCard">添加关键卡组</el-button>
                                <div>
                                    <el-row style="margin-top: 10px" v-for="(v,k) in form.card_detail" :key="k">
                                        <el-col :span="4">
                                            <el-image
                                                    :src="v.img"
                                                    style="width: 100px"
                                            ></el-image>
                                        </el-col>
                                        <el-col :span="14">
                                            <el-input type="textarea" rows="6" disabled="disabled" :value="v.text"></el-input>
                                        </el-col>
                                        <el-col :span="4" :offset="2">
                                            <el-button type="success" @click="editCard(k)">编辑</el-button>
                                            <el-button type="danger" @click="delCard(k)">删除</el-button>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-form-item>
                        </div>
                        <!--视频产品预组有-->
                        <div v-if="$route.query.type == 3">
                            <el-form-item label="是否显示">
                                <el-select v-model="form.video_show">
                                    <el-option label="显示" value="1"></el-option>
                                    <el-option label="隐藏" value="0"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="视频标题">
                                <el-input v-model="form.video_title"></el-input>
                            </el-form-item>
                            <el-form-item label="视频代码">
                                <el-input type="textarea" rows="5" v-model="form.video_url"></el-input>
                            </el-form-item>
                        </div>

                        <el-form-item>
                            <el-button type="success" @click="submitForm">立即提交</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>

            <div>
                <el-dialog title="关键卡片" :visible.sync="cardDeckShow" v-if="cardDeckShow">
                    <CardDeck @passSubmitCard="passSubmitCard" :cardEditVal="cardEditVal"></CardDeck>
                </el-dialog>
            </div>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/admin/breadcrumb'
    import Editor from '@/components/admin/editor'
    import CardDeck from './components/card-deck'

    export default {
        name: "add",
        components:{Breadcrumb,Editor,CardDeck},
        data() {
            return {
                form: {
                    type:0,
                    deck_id:"",
                    top_area:"",
                    card_detail_show:"0",
                    card_detail:[],
                    video_show:"0",
                    video:{},
                    video_title:"",
                    video_url:"",
                    nav_title:"",
                    title:"",
                    edit_at:"",
                },
                deckOptions : [], // {img:"",text:""}
                cardDeckShow:false,
                cardEditVal:{img:"", text:"", isEdit:false, index:0,}
            }
        },
        methods: {
            getDeckOptions() {
                let type = "product";
                if (this.form.type == 2){
                    type = "recommend";
                }
                this.$axios.post("site/siteDeck/deckOptions",{type})
                    .then((res)=>{
                        this.deckOptions = res.data.data;
                })
            },
            passSubmitCard(obj){
                this.cardDeckShow = false;
                if (obj.isEdit===true){
                    this.form.card_detail[obj.index] = {img:obj.img,text:obj.text}
                } else {
                    this.form.card_detail.push({img:obj.img,text:obj.text});
                }
            },
            getEditVal(v){
                this.form.top_area = v;
            },
            delCard(index) {
                this.form.card_detail.splice(index,1)
            },
            editCard(index){
                let editObj = this.form.card_detail[index];
                this.cardEditVal = {
                    img:    editObj.img,
                    text:   editObj.text,
                    isEdit: true,
                    index:  index,
                };
                this.cardDeckShow = true;
            },
            addCard() {
                this.cardEditVal = {img:"",text:""};
                this.cardDeckShow = true;
            },
            submitForm(){
                this.video = {title:this.form.video_title,video:this.form.video_url}
                this.$axios.post("site/siteDeck/saveDeck",this.form)
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.$message.success("保存成功");
                            setTimeout(()=>{
                                this.$router.push("recommendDecks")
                            },1000)
                        }else{
                            this.$message.error(res.data.msg)
                        }
                })
            }
        },
        created() {
            this.form.type = this.$route.query.type;
            this.getDeckOptions();
        }
    }
</script>

<style scoped>

</style>
